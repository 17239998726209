.scoreboard {
  background: #fff;
  border-radius: 4px;
  position: relative;

  &__header {
    font-size: 14px;
    font-weight: 700;
    border-bottom: 1px solid #f1f4f8;     
  }

  &__header .cell {
    padding-bottom: 0.875rem;
  }

  &__card {
    position: absolute;
    width: 100%;
    will-change: transform, height, opacity;
  }

  &__player {
    border-bottom: 1px solid #f1f4f8;
    font-size: 14px;
  }

  &__player:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.01);
  }

  &__stats {
    font-size: 14px;
    font-weight: 400;    
    color: #869ab8;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__stats > div {
    padding: 1rem;
  }  

}

.row {
  display: grid;
  grid-template-columns: 50px 1fr 65px; 
}

.cell {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  &--bold {
    font-weight: 700;
  }

  &:last-child {
    text-align: right;
    justify-content: flex-end;
  }

}

.header-matches,
.player-matches {
  display: none;
}

.player-position {
  justify-content: center;
}

.header-rating,
.player-rating {
  justify-content: flex-end;
}

.player-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 0.5rem;
  object-fit: cover;
  object-position: center;
}

@media screen and (min-width: 992px) {

  .row {  
    grid-template-columns: 50px 1fr 1fr 1fr; 
  }

  .cell {
    padding: 1rem 2rem;
  }

  .header-position,
  .player-position {
    padding: 1rem;
  }

  .scoreboard__header .cell {
    padding-bottom: 0.875rem;
  }

  .header-position,
  .player-position,
  .header-matches,
  .player-matches {
    display: flex;
  }

  .scoreboard__stats > div:first-child {
    padding: 1rem;
  }

  .scoreboard__stats > div {
    padding: 1rem 2rem;
  }

}
