.nav-bar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0 1rem;
    height: 4rem;
    transform: translateY(0);
    transition: transform 200ms ease-in-out;
    background: #fff;
    overflow: visible;
    position: relative;
    z-index: var(--zindex-header);
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    a {
      text-decoration: none;
      color: rgba(0, 0, 0, 0.87);      
    }

  &__logo {
    grid-column: left / span 1;    
    text-decoration: none;
  }

  &__logo-link {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__logo-link svg {
    margin-right: 0.5rem;
  }

  &__title {
    display: none;
  }

  &__actions {
    grid-column-start: right;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .profile-icon {
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 50%;
      margin-left: 1rem;
  }

  @media screen and (min-width: 992px) {
    padding-left: 2rem;
    padding-right: 2rem;

    &__title {
      display: block;
    }

    .profile-icon {
      margin-left: 0.5rem;
      height: 2.5rem;
      width: 2.5rem;
    }
    
  }


}