.addMatchForm {
  &__title {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    margin: 0 0 0.875rem;
    text-align: center;
  }

  &__settings {
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__grid {
    display: flex;
    flex-direction: row;
  }

  &__grid-column {
    flex-basis: 50%;
    padding: 1rem;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.875rem;

  label {
    display: block;
    margin-bottom: 0.375rem;
    font-size: 14px;
  }
}

.btns {
  --btns-button-radius: 0.25em;
  --btns-button-padding-x: var(--space-sm);
  --btns-button-padding-y: var(--space-sm);
  display: inline-block; // flex fallback
  display: inline-flex;

  > * {
    display: inline-block; // flex fallback
  }

  > *:first-child,
  *:first-child .btns__btn {
    border-radius: var(--btns-button-radius) 0 0 var(--btns-button-radius);
  }

  > *:last-child,
  *:last-child .btns__btn {
    border-radius: 0 var(--btns-button-radius) var(--btns-button-radius) 0;
  }
}

// make btns compatible with the gap utility classes of CodyFrame
.btns[class*="gap-xxxxs"], .btns[class*="gap-xxxs"], .btns[class*="gap-xxs"], .btns[class*="gap-xs"], .btns[class*="gap-sm"], .btns[class*="gap-md"], .btns[class*="gap-lg"], .btns[class*="gap-xl"], .btns[class*="gap-xxl"], .btns[class*="gap-xxxl"], .btns[class*="gap-xxxxl"] {
  flex-wrap: wrap;

  .btns__btn {
    border-radius: var(--btns-button-radius) !important;
  }
}

// single button
.btns__btn {
  display: block; // fallback
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: var(--space-xs) var(--space-sm);
  background-color: var(--color-contrast-lower);
  transition: .2s;
  cursor: pointer;

  &:hover:not(.btns__btn--selected) {
    background-color: var(--color-contrast-lower);
  }

  &:focus {
    z-index: 1;
    outline: none;
    box-shadow: 0 0 0 2px var(--color-primary);
  }
}

.btns__btn--selected { //  selected style
  background-color: var(--color-contrast-higher);
  color: var(--color-bg);
  box-shadow: var(--shadow-sm);
}

.btns__btn--disabled {
  opacity: 0.7;
  cursor: not-allowed;
  text-decoration: line-through;
}

// --radio, --checkbox
.btns--radio, .btns--checkbox {
  > * {
    position: relative;
  }

  input[type="radio"],
  input[type="checkbox"] {
    /* hide native buttons */
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    height: 0;
    width: 0;
    pointer-events: none;
  }

  input[type="radio"] + label,
  input[type="checkbox"] + label {
    user-select: none;
  }

  input[type="radio"]:focus + label,
  input[type="checkbox"]:focus + label {
    z-index: 1;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.12);
  }

  input[type="radio"]:checked + label,
  input[type="checkbox"]:checked + label {
    /* checked state */
    @extend .btns__btn--selected;
  }
}

.btns__btn--icon {
  padding: var(--btns-button-padding-x);

  .icon {
    display: block;
    color: inherit;
  }
}